<template>
  <div class="app-mall-goods-detail container">
    <div class="content padding-20-0 h s">
      <div class="flex" style="width: 790px;">
        <template v-if="detail">
          <div class="h s" style="padding-bottom: 20px;">
            <div>
              <div class="thumbnail bc-l" :style="mainThumbnail"></div>
              <div class="thumbnail-list h wrap">
                <div class="item" v-for="(img, i) in images" :key="img.id" :class="{selected: imageIndex === i}" :style="{backgroundImage: `url(${img.url}?x-oss-process=image/resize,l_500)`}" @click="imageIndex = i"></div>
              </div>
            </div>
            <el-form label-position="right" label-width="auto" label-suffix="：" class="flex">
              <div class="fs-huge name">{{detail.name}}</div>
              <div class="fc-e padding-10-0" v-if="detail.disableType ==='allQuantity'">当前组合为限量销售，目前已经售罄</div>
              <div class="fc-e padding-10-0" v-else-if="detail.disableType ==='maxTimes'">当前组合每个用户限购{{selected.maxTimes}}套，您不可再进行购买</div>
              <template v-if="saleable">
                <form-info-item label="促销方式">{{methods[detail.method]}}</form-info-item>
                <template v-if="detail.disableType === 'normal'">
                  <form-info-item label="起订数量">{{detail.minBuyQuantity}}</form-info-item>
                  <form-info-item label="剩余数量" v-if="detail.allQuantity > 0">{{detail.allQuantity}}</form-info-item>
                </template>
                <form-info-item label="套餐内容"></form-info-item>
                <div class="group-sale-list">
                  <div v-for="(o,i) in detail.items" :key="o.id" class="group-sale-item">
                    <div class="padding-10 h c sb fs-small" style="background-color: #f2f2f2;">
                      <span>内容{{i+1}}</span>
                      <span>需要以下商品的购买数量总和为：{{o.quantity}}</span>
                    </div>
                    <el-table :data="o.goodsList" row-key="goodsId" style="margin-bottom: -1px;">
                      <el-table-column label="商品">
                        <template slot-scope="scope">
                          <div>
                            <span>{{scope.row.goodsName}}</span>
                            <span class="fc-g">（{{$goodsSpecConvert(scope.row.spec)}}）</span>
                          </div>
                          <!-- <div v-if="o.method === 'discount'">{{Math.round(o.discount * 1000) / 100}}折</div>
                          <div v-else-if="o.method === 'save'">单价优惠{{$price(scope.row.saveAmount)}}</div>
                          <div v-else-if="o.method === 'price'">单价一口价{{$price(scope.row.price)}}</div>-->
                        </template>
                      </el-table-column>

                      <el-table-column label="单价" width="100" align="right">
                        <template slot-scope="scope">
                          <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                          <div v-else-if="scope.row._price.error" class="h c">
                            <span class="fc-e">{{scope.row._price.error}}</span>
                            <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                              &nbsp;
                              <i class="el-icon-refresh"></i>
                            </a>
                          </div>
                          <div style="line-height: 1.5" class="v e" v-else>
                            <b class="fc-e">{{$price(scope.row._price.value)}}</b>
                            <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value">{{$price(scope.row._price.oldValue)}}</span>
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column label="数量" width="150" v-if="detail.disableType === 'normal'">
                        <template slot-scope="scope">
                          <el-input-number size="mini" v-model="scope.row._quantity" :min="0" :max="o.quantity" :step="1" :precision="2" @change="$checkNaN(scope.row, '_quantity', 0, loadGoodsPrice)" style="width: 100%" />
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <form-info-item label="套餐单价">
                  <b class="fc-e">{{groupPrice}}</b>
                </form-info-item>
                <div class="h e" v-if="detail.disableType === 'normal'">
                  <div style="margin-right: 10px;">
                    <form-info-item label="购买套餐数量"></form-info-item>
                    <el-input-number v-model="nums" :min="detail.minBuyQuantity" :max="detail.allQuantity === 0 ? 9999 : detail.allQuantity" :step="1" :precision="0" controls-position="right" step-strictly @change="handleNumsChange" style="width: 120px; margin-top: 5px;" />
                  </div>
                  <el-button type="danger" :disabled="uncommitable" @click="handleCartAdd">加入购物车</el-button>
                  <el-button type="success" :disabled="uncommitable" @click="handleBuy">立即购买</el-button>
                </div>
              </template>
              <div class="padding-20-0 fc-e" v-else>当前供应商不提供该组合销售。</div>
            </el-form>
          </div>

          <div class="infos" v-if="saleable">
            <form-info-item label="商品介绍" label-width="auto" label-suffix="："></form-info-item>
            <el-tabs v-model="current">
              <el-tab-pane v-for="(item, k) in spus" :key="k" :label="item" :name="k" />
            </el-tabs>
            <div class="v" v-html="describe" v-loading="loading" style="min-height: 100px;"></div>
          </div>
        </template>
      </div>
      <div class="right-column">
        <history :supplier="supplier" :exclude="detail ? detail.id : null" />
      </div>
    </div>
  </div>
</template>

<script>
import History from "../../assembly/history";
import { combine } from "@/api/marketing";
import { getMallSpu } from "@/api/goods";
import { getPurchaseGoodsPrice } from "@/api/purchaseOrder";
import request from "@/utils/request";

export default {
  components: { History },
  props: {
    supplier: String | Number
  },
  computed: {
    groupPrice() {
      let m = 0;
      if (this.uncommitable) return "所选内容不合要求";
      else if (this.detail && this.detail.items && this.detail.items.length) {
        this.detail.items.forEach(o => {
          o.goodsList.forEach(g => {
            if (g._quantity > 0) {
              m += g._quantity * g._price.value;
            }
          });
        });
      }
      return this.$price(m);
    },
    uncommitable() {
      let unable = true;
      if (this.detail && this.detail.items && this.detail.items.length) {
        unable =
          this.detail.items.filter(o => {
            let qs = 0;
            o.goodsList.forEach(g => {
              qs += g._quantity || 0;
            });
            return qs !== o.quantity;
          }).length > 0;
      }
      return unable;
    },
    mainThumbnail() {
      if (
        this.images &&
        this.images.length &&
        this.imageIndex >= 0 &&
        this.imageIndex < this.images.length
      ) {
        return { backgroundImage: `url(${this.images[this.imageIndex].url}?x-oss-process=image/resize,l_500)` };
      } else {
        return {};
      }
    }
  },
  data() {
    return {
      promotion: null,
      detail: null,
      images: [],
      imageIndex: 0,
      spus: [],
      methods: {
        discount: "打折",
        save: "减价",
        price: "一口价"
      },
      nums: 1,
      saleable: false,
      current: null,
      loading: false,
      describe: ""
    };
  },
  watch: {
    supplier: "loadDetail",
    current: "loadGoodsInfo"
  },
  methods: {
    loadDetail() {
      this.loading = true;
      this.current = null;
      combine
        .getItem(atob(this.$route.params.id))
        .then(res => {
          if (res.promotion && res.groupSaleItem) {
            this.nums = res.groupSaleItem.minBuyQuantity;
            if (res.promotion.entId === this.supplier) {
              this.saleable = true;
              if (res.groupSaleItem.items && res.groupSaleItem.items.length) {
                let spus = {},
                  defSpu;

                res.groupSaleItem.items.forEach(o => {
                  o.goodsList.forEach((g, i) => {
                    if (!defSpu) defSpu = g.spuId;
                    spus[g.spuId] = g.goodsName;
                    g._price = {
                      loading: false,
                      error: false,
                      oldValue: 0,
                      value: 0
                    };
                    g._quantity = i === 0 ? o.quantity : 0;
                    g.groupSaleRule = {
                      method: o.method,
                      discount: o.discount,
                      saveAmount: g.saveAmount,
                      price: g.price,
                      quantity: g._quantity
                    };
                    this.loadGoodsPrice(g);
                  });
                });
                this.spus = spus;
                this.current = defSpu;
              } else {
                this.current = null;
              }
            } else {
              this.saleable = false;
            }
            this.promotion = res.promotion;
            this.detail = res.groupSaleItem;
            this.loadImages();
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    loadImages() {
      request({
        url: "api/file/search",
        method: "get",
        params: {
          page: 0,
          size: 1000,
          entityId: this.detail.fileId,
          sort: "displayNo,asc",
          folders: ["marketing/combine/cover", "marketing/combine/detail"]
        }
      }).then(res => {
        this.images = res.content;
      });
    },
    loadGoodsInfo() {
      if (this.current) {
        this.loading = true;
        getMallSpu(this.current, this.supplier)
          .then(res => {
            this.describe = res.info;
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        this.describe = "";
      }
    },
    loadGoodsPrice(g) {
      if (g && g.goodsId) {
        g._price.loading = true;
        g._price.error = false;
        g._price.oldValue = null;
        g._price.value = null;
        getPurchaseGoodsPrice({
          goodsId: g.goodsId,
          quantity: g._quantity * this.nums || 1,
          sellerId: this.supplier
        })
          .then(res => {
            let v = res.goodsPrice;
            if (typeof v !== "number") {
              g._price.error = "没有价格";
            } else {
              if (g.groupSaleRule.method === "price") {
                v = g.groupSaleRule.price;
              } else if (g.groupSaleRule.method === "save") {
                v -= g.groupSaleRule.saveAmount;
              } else if (g.groupSaleRule.method === "discount") {
                v = Math.round(v * g.groupSaleRule.discount);
              }
            }
            g._price.oldValue = res.goodsPrice;
            g._price.value = v < 0 ? 0 : v;
            g._price.loading = false;
          })
          .catch(err => {
            g._price.error = err.message || "获取商品价格失败";
            g._price.loading = false;
          });
      }
    },
    handleNumsChange(val) {
      if (typeof this.nums !== "number") {
        this.$nextTick(_ => {
          this.nums = this.detail.minBuyQuantity;
          this.handleNumsChange();
        });
      } else if (this.detail && this.detail.items && this.detail.items.length) {
        this.detail.items.forEach(o => {
          (o.goodsList || []).forEach(g => {
            this.loadGoodsPrice(g);
          });
        });
      }
    },
    generateGroupSaleItem(includePrice = false) {
      if (this.detail && this.detail.items && this.detail.items.length) {
        let ids = [],
          items = [];
        this.detail.items.forEach(o => {
          (o.goodsList || []).forEach(g => {
            if (g._quantity > 0) {
              ids.push(g.id + "_" + g._quantity.toFixed(2));

              let ng = {
                _id: this.$uuid(),
                goodsId: g.goodsId,
                goodsSpec: g.spec,
                goodsName: g.goodsName,
                minSalesQuantity:
                  g.groupSaleRule.quantity * this.detail.minBuyQuantity,
                code: g.code,
                erpCode: g.erpCode,
                deliveryCycle: g.deliveryCycle || 7,
                count: g._quantity * this.nums,
                info: "",
                groupId: g.groupId,
                groupMinBuyQuantity: this.detail.minBuyQuantity,
                discountAmount: 0,
                giftDiscountPrice: 0,
                giftDiscountAmount: 0,
                promotionId: g.promotionId,
                promotionJoinType: "groupSale",
                promotionMutex: this.detail.mutex,
                wholePromotionId: null,
                wholePromotionName: null,
                wholeGroupId: null,
                singleGoodsGroupId: null,
                singleGoodsLadderId: null,
                groupSaleId: g.groupSalesId,
                groupSaleItemGoodsId: g.id,
                groupSaleName: this.detail.name,
                groupSaleRule: g.groupSaleRule
              };
              ng.groupSaleRule.quantity = g._quantity;
              if (includePrice) {
                ng._price = g._price;
              }
              items.push(ng);
            }
          });
        });
        if (items && items.length) {
          return {
            _id: this.detail.id + "," + ids.sort().join(","),
            groupId: this.$uuid(),
            groupSaleName: this.detail.name,
            discountAmount: 0,
            coverImg: this.detail.coverImg,
            times: this.nums,
            minTimes: this.detail.minBuyQuantity,
            maxTimes: this.detail.allQuantity,
            items: items
          };
        }
      }

      return null;
    },
    handleCartAdd() {
      let item = this.generateGroupSaleItem();
      if (item) {
        window.mall.cart.add("group", item);
      }
    },
    handleBuy() {
      let item = this.generateGroupSaleItem(true);
      if (item) {
        window.localStorage.setItem(
          "cart-confirm",
          JSON.stringify({ goods: [], groups: [item] })
        );
        this.$router.push({
          path: "/mall/cart/confirm",
          query: {
            mode: "1"
          }
        });
      }
    }
  },
  activated() {
    this.promotion = null;
    this.detail = null;
    this.images = [];
    this.imageIndex = 0;
    this.nums = 1;
    this.current = null;
    this.describe = null;
    if (this.$route.params.id) {
      this.loadDetail();
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.loadDetail();
  }
};
</script>